import React, { useContext, useEffect, useState, useRef } from "react";
import { IoDownload, IoLink, IoPrint, IoShareOutline } from "react-icons/io5";
// import QRCode from "react-qr-code";
import { QRCode } from "react-qrcode-logo";
import { Redirect, useHistory } from "react-router";
import CardCard from "../components/CardCard";
import { AuthContext } from "../contexts/AuthContext";
import app from "../data/firebase";
import { Modal } from "react-bootstrap";
import { guid, svgToPng } from "../helpers/utils";
import { constants } from "../helpers/constants";
import Footer from "./../components/Footer";
import CreateCardModal from "./../components/modals/CreateCardModal";
import BordersContext from "./../contexts/BordersContext";

const CardsPage = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [link, setLink] = useState("");
  const [currentName, setCurrentName] = useState("");
  const [downloadLink, setDownloadLink] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [name, setName] = useState("Новая открытка");
  const textArea = useRef();

  useEffect(() => {
    (async () => {
      setLoading(true);
      let cards = await getCards();
      setCards(
        cards
          .map((c, i) => {
            if (!c.createdAt) {
              c.createdAt = new Date(Date.parse("2021-01-01"));
            } else {
              c.createdAt = new Date(c.createdAt.seconds * 1000);
            }
            console.log(c.createdAt);
            return c;
          })
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      );
      setLoading(false);
    })();
  }, []);

  const onCreateClicked = () => {
    setShowCreationModal(true);
  };

  const createCard = async () => {
    let cardId = guid();
    let newCard = {
      cardId,
      blocks: [],
      userId: currentUser.uid,
      name,
      from,
      to,
      createdAt: new Date(),
    };
    try {
      await app.firestore().collection("cards").doc(cardId).set(newCard);
      history.push(`/create?id=${cardId}`);
      console.log(newCard);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCard = (id) => {
    let card = cards.find((c) => c.id === id);
    for (const block of card.blocks) {
      for (const img of block.images) {
        try {
          app.storage().ref(`/cards_images/${img.name}`).delete();
        } catch (error) {
          console.log(error);
        }
      }
      app.storage().ref(`/audio_files/${block.audioName}`).delete();
    }
    setCards((prev) => {
      let cards = [...prev];
      return cards.filter((c) => c.id !== id);
    });
  };

  const shareCard = (id, name = "new_card") => {
    let link = `${constants.ENDPOINT}/cards/${id}`;
    setLink(link);
    setCurrentName(name);

    setShowModal(true);
  };

  const getCards = async () => {
    let cards = await app
      .firestore()
      .collection("cards")
      .where("userId", "==", currentUser.uid)
      .get()
      .then((qs) => {
        let data = [];
        qs.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return data;
      });

    return cards;
  };

  const onShareClicked = () => {
    setShowModal(true);
  };

  // const downloadQR = async () => {
  //   const qrCode = document.getElementById("qrCodeEl");
  //   let cloned = qrCode.cloneNode(true);
  //   let outerHTML = cloned.outerHTML;
  //   // let blob = new Blob([outerHTML], { type: "image/svg+xml;charset=utf-8" });
  //   // saveData(blob, "sheesh.png");
  //   let imageUrl = await svgToPng(outerHTML).then((data) => {
  //     console.log(data);
  //     return data;
  //   });
  //   var a = document.createElement("a"); //Create <a>
  //   a.href = imageUrl; //Image Base64 Goes here
  //   a.download = `qr-${currentName}.png`; //File name Here
  //   a.click(); //Downloaded file
  // };

  const downloadQR = async () => {
    var canvas = document.getElementById("react-qrcode-logo");
    // Convert the canvas to data
    var image = canvas.toDataURL();
    // Create a link
    var a = document.createElement("a"); //Create <a>
    a.href = image; //Image Base64 Goes here
    a.download = `qr-${currentName}.png`; //File name Here
    a.click(); //Downloaded file
  };

  const copyLink = () => {};

  const onCloseModal = () => {
    setShowModal(false);
    setShowCreationModal(false);
  };

  const copyToClipboard = (e) => {
    textArea.current.select();
    document.execCommand("copy");
  };

  return (
    <div>
      <div className="d-flex flex-column p-4">
        <div className="d-flex flex-sm-row flex-column justify-content-between">
          <h1 style={{ fontWeight: "lighter" }}>Мои открытки</h1>
          <div className="d-flex flex-row justify-content-lg-center justify-content-end align-items-center">
            <button
              className="btn btn-secondary border-0 primary-bg rounded-pill"
              onClick={onCreateClicked}
              type="button"
            >
              Создать открытку
            </button>
            <button
              className="btn btn-danger mx-3 rounded-pill"
              onClick={() => {
                app.auth().signOut();
              }}
              type="button"
            >
              Выход
            </button>
          </div>
        </div>
        <hr />
        <div className="d-flex align-items-center justify-content-center">
          {loading && <img src="../spinner.svg" width="50px" height="50px" />}
          {cards.length === 0 && !loading && <span>Нет открыток!</span>}
        </div>
        <div
          className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4  g-4"
          style={{ minHeight: "350px" }}
        >
          {cards.map((c, i) => {
            return (
              <div className="col" key={c.id}>
                <CardCard
                  data={c}
                  link={`/create?id=${c.id}`}
                  onDeleted={deleteCard}
                  onSharePressed={shareCard}
                />
              </div>
            );
          })}
        </div>

        {/* Share modal */}
        <Modal show={showModal}>
          <Modal.Header>
            <h5 className="modal-title" id="staticBackdropLabel">
              Поделитесь открыткой
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={onCloseModal}
            ></button>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <QRCode
                id="qrCodeEl"
                value={link}
                size={256}
                ecLevel={"L"}
                logoImage="../../favicon.ico"
                qrStyle="dots"
                eyeRadius={5}
                logoWidth={53}
              />
              <div className="d-flex justify-content-center align-items-center text-center w-100 mt-2">
                <textarea
                  ref={textArea}
                  id="linkArea"
                  className="text-center w-100 form-control"
                  value={link}
                  readOnly
                  style={{ resize: "none" }}
                ></textarea>
              </div>
              <div className="d-flex flex-row mt-4">
                <div className="btn-group">
                  <a className="btn btn-light" onClick={copyToClipboard}>
                    <IoLink size={26} /> Скопировать ссылку
                  </a>
                  <a className="btn btn-secondary" onClick={downloadQR}>
                    <IoDownload size={26} />
                  </a>
                  {/* <button className="btn btn-secondary">
                  <IoPrint size={26} />
                </button> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary rounded-pill"
              onClick={onCloseModal}
            >
              Закрыть
            </button>
          </Modal.Footer>
        </Modal>
        {/* CreateModal */}
        <CreateCardModal
          showModal={showCreationModal}
          from={from}
          to={to}
          name={name}
          onCloseModal={onCloseModal}
          onCreateClicked={createCard}
          setFrom={setFrom}
          setTo={setTo}
          setName={setName}
        />
      </div>
      <Footer />
    </div>
  );
};

export default CardsPage;
