import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import CardPreview from "../components/CardPreview";
import app from "../data/firebase";
import Block from "./../components/editor/Block";

const CardPublishedPage = () => {
  const [blocks, setBlocks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [opened, setOpened] = useState(false);
  const [address, setAddress] = useState({});
  const [touchAction, setTouchAction] = useState(false);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      setLoading(true);
      let cardData = await getCardData(id);
      if (!cardData) {
        history.goBack();
      }
      document.body.style.backgroundColor =
        cardData.blocks[cardData.blocks.length - 1].backgroundColor;

      setBlocks(cardData.blocks);
      setLoading(false);
      setAddress({ from: cardData.from, to: cardData.to });
    })();
  }, []);

  const getCardData = async (id) => {
    let cardData = await app
      .firestore()
      .collection("cards")
      .doc(id)
      .get()
      .then((doc) => doc.data());
    return cardData;
  };

  const openCard = () => {
    setTimeout(() => {
      setOpened(true);
      setTouchAction(true);
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", blocks[blocks.length - 1].backgroundColor);
      let audio = document.getElementsByTagName("audio")[0];
      if (audio) {
        audio.play();
      }
    }, 500);
  };

  return (
    <div
      className="d-flex vh-100 flex-column justify-content-between"
      style={{ touchAction: touchAction ? "auto" : "none" }}
    >
      <div className="d-flex flex-column">
        <div className="d-flex flex-column align-items-center">
          {loading && <img src="../spinner.svg" />}
          {blocks.map((block, i) => {
            return (
              <Block data={block} key={block.id} editing={false} key={i} />
            );
          })}
        </div>
      </div>
      {!opened && (
        <CardPreview
          onButtonPressed={openCard}
          isReady={!loading}
          from={address.from}
          to={address.to}
        />
      )}
      {!loading && (
        <footer className="d-flex flex-column justify-content-center align-items-center container-fluid py-4 bg-secondary">
          <div>
            <span className="text-white">Сделано с помощью</span>
          </div>
          <a href="/">
            <img src="../bonfetta_logo.svg" height="30px" />
          </a>
        </footer>
      )}
    </div>
  );
};

export default CardPublishedPage;
