import React, { useRef, useState } from "react";
import EditableText from "../editor/EditableText";
import Image from "./../editor/Image";

const BlockType2 = ({
  blockData = {},
  editing = true,
  onTextChanged = (e) => {},
  onImagePicked = () => {},
}) => {
  const [imageUri, setImageUri] = useState("");
  return (
    <div className="row container-fluid justify-content-center">
      <div className="col-lg-4 d-flex flex-column">
        <div>
          <EditableText
            onTextChanged={onTextChanged}
            type="header"
            editing={editing}
            text={blockData.header}
            fontSize={blockData.headerSize}
            fontWeight={blockData.headerWeight}
            color={blockData.textColor}
            fontFamily={blockData.fontFamily}
          />
        </div>
        <div className="mt-3 d-flex h-75 align-items-center">
          <EditableText
            type="text"
            editing={editing}
            onTextChanged={onTextChanged}
            text={blockData.text}
            fontSize={blockData.textSize}
            color={blockData.textColor}
            fontFamily={blockData.fontFamily}
          />
        </div>
      </div>
      <div className="col-lg-4 d-flex justify-content-center align-items-center">
        <Image
          editing={editing}
          imageUri={blockData.images[0]?.uri}
          onImagePicked={onImagePicked}
          imageNumber={0}
          borderData={blockData.images[0]?.border}
        />
      </div>
    </div>
  );
};

export default BlockType2;
