import React, { useContext } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { Redirect } from "react-router";
import NavBar from "../components/NavBar";
import { AuthContext } from "../contexts/AuthContext";
import app from "../data/firebase";

const ProfileLayout = ({ children }) => {
  const { currentUser, isAuthLoading } = useContext(AuthContext);

  if (!currentUser && !isAuthLoading) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="container-fluid p-0" style={{ height: "100vh" }}>
      <NavBar />
      {currentUser && <div className="col pt-5">{children}</div>}
    </div>
  );
};

export default ProfileLayout;
