import React from "react";
import { Modal } from "react-bootstrap";

const CreateCardModal = ({
  onCreateClicked = () => {},
  setName = () => {},
  setTo = () => {},
  setFrom = () => {},
  name,
  to,
  from,
  onCloseModal = () => {},
  showModal = false,
}) => {
  return (
    <Modal show={showModal}>
      <Modal.Header>
        <h5 className="modal-title" id="staticBackdropLabel">
          Создайте открытку
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={onCloseModal}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="my-3">
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              <strong>Название</strong>
            </label>
            <input
              type="text"
              value={name}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Новая открытка"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              <strong>От кого</strong>
            </label>
            <input
              type="text"
              value={from}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Меня"
              onChange={(e) => setFrom(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label for="exampleFormControlInput1" className="form-label">
              <strong>Кому</strong>
            </label>
            <input
              type="text"
              value={to}
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Тебе"
              onChange={(e) => setTo(e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-secondary primary-bg border-0 rounded-pill p-2"
          onClick={onCreateClicked}
        >
          Создать открытку
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCardModal;
