import { Button } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import { IoPlayOutline } from "react-icons/io5";
import Modal from "./../components/Modal";
import { AuthContext } from "../contexts/AuthContext";
import LoginForm from "./../components/LoginForm";
import { useSpring, animated, config } from "react-spring";
import { QRCode } from "react-qrcode-logo";

const Home = () => {
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {}, []);

  const props = useSpring({
    from: { opacity: 0, transform: "translateX(-200px)" },
    to: { opacity: 1, transform: "translateX(0)" },
    config: config.molasses,
  });

  return (
    <div>
      <section
        style={{ paddingTop: "100px" }}
        className="landing-block primary-bg pb-5"
      >
        <div className="d-flex container-fluid row justify-content-evenly p-4 m-0">
          <div className="d-flex align-items-center col col-sm-5">
            <h1 className="header text-left text-xs-center lh-lg fs-1">
              Порадуйте тех, кто вам дорог
            </h1>
          </div>
          <div className="d-flex flex-column justify-content-center col col-sm-5">
            <p className="text-left w-100 subheader">
              Создавайте уникальные цифровые открытки, которые оставят память на
              всю жизнь не только в сердце, но и в интернете.
            </p>
            <div className="d-flex justify-content-between justify-content-sm-start mt-4">
              <div className="me-1">
                <a className="btn btn-light" href="/cards" role="button">
                  Создать открытку
                </a>
              </div>
              <div>
                <a className="btn btn-outline-light me-1" href="/#howto">
                  Как это работает?
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="landing-block bg-white">
        <div className="d-flex container-fluid row justify-content-evenly p-4 m-0">
          <div className="d-flex justify-content-center col-md-5">
            <animated.img
              src="../assets/landing_i_1.png"
              style={{ width: "300px", height: "300px", ...props }}
            />
          </div>
          <div className="d-flex flex-column justify-content-start col-md-5">
            <h2 className="header text-md-start text-center">
              Создайте сайт-открытку
            </h2>
            <p className="text-md-start text-center text-secondary mt-4 fs-5">
              В нашем конструкторе вам предложен выбор из блоков, компонуя
              которые вы можете получить необычный подарок, который точно удивит
              адресата!
            </p>
          </div>
        </div>
      </section>

      <section className="landing-block bg-light">
        <div className="d-flex container-fluid row flex-row-reverse justify-content-evenly p-4 m-0">
          <div className="d-flex flex-column justify-content-start col-md-6 order-2">
            <h2 className="header text-md-start text-center">
              Отправьте ссылку
            </h2>
            <p className="text-md-start text-center text-secondary mt-4 fs-5">
              После создания конструктора вы можете сгенерировать ссылку на
              созданную открытку и отправить ее любым удобным способом. Там же
              можно скачать и QR-код, чтобы вручить его лично при встрече.
            </p>
          </div>
          <div className="d-flex justify-content-center col-md-6 order-md-1 order-1">
            <animated.img
              src="../assets/landing_i_2.png"
              style={{ width: "300px", height: "300px", ...props }}
            />
          </div>
        </div>
      </section>

      <section className="landing-block bg-white py-5" id="about">
        <div className="container">
          <div className="d-flex align-items-center flex-column">
            <h2 className="header">О проекте</h2>
            <p className="text-center w-75 text-secondary mt-4 fs-4">
              <strong>Bonfetta</strong> - это сервис, который позволяет легко и
              просто создавать бесплатные цифровые поздравительные открытки в
              виде сайтов.
              <br />
              Создавая открытки с помощью нашей платформы, вы не только делаете
              приятно вашим близким, но и помогаете экономить бумагу и сохранять
              леса. Ведь такие открытки сохранятся надолго!
              <br />
              <strong>Настолько надолго, что аж навсегда!</strong>
            </p>
          </div>
        </div>
      </section>

      <section className="landing-block bg-light py-5" id="howto">
        <div className="container">
          <div className="d-flex align-items-center flex-column">
            <h2 className="header">Пример</h2>
            <p className="text-center w-75 text-secondary mt-2 subheader">
              Посмотрите, что вы можете сделать на нашей платформе, кликнув на
              ссылку ниже!
            </p>

            <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
              <a
                className="btn btn-lg btn-secondary primary-bg border-0 rounded-pill"
                href="/cards/d4f4bd2f-a8a8-4d43-8bff-31c439b6000a"
              >
                Нажми на меня!
              </a>
            </div>
            <p className="text-center w-75 text-secondary mt-4 subheader">
              Или просто сканируйте QR-код!
            </p>
            <div className="col col-lg-6 p-2 d-flex justify-content-center align-items-center">
              <QRCode
                id="qrCodeEl"
                value={
                  "https://bonfetta.com/cards/d4f4bd2f-a8a8-4d43-8bff-31c439b6000a"
                }
                size={256}
                ecLevel={"L"}
                logoImage="../../favicon.ico"
                qrStyle="dots"
                eyeRadius={5}
                logoWidth={53}
              />
            </div>
          </div>
        </div>
      </section>

      {!currentUser && (
        <section className="landing-block bg-white py-5" id="login">
          <div className="container">
            <div className="d-flex align-items-center flex-column">
              <h1 className="header">Начните прямо сейчас</h1>
            </div>
            <div className="d-flex align-items-center flex-column mt-4">
              <h2 className="header">Вход</h2>
              <div className="col-lg-6 col-12">
                <LoginForm />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Home;
