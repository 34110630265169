import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import {
  IoImageOutline,
  IoScan,
  IoScanOutline,
  IoSquareOutline,
  IoTabletLandscapeOutline,
} from "react-icons/io5";
import BlocksContext from "../../contexts/BlocksContext";
import BordersContext from "../../contexts/BordersContext";
import { constants } from "../../helpers/constants";
import BorderTypeCard from "./BorderTypeCard";

const Image = ({
  editing = true,
  imageNumber = 0,
  imageUri = "",
  borderData = {},
  type = "medium",
  onImagePicked = () => {},
}) => {
  const blocksContext = useContext(BlocksContext);
  const bordersContext = useContext(BordersContext);

  const [showModal, setShowModal] = useState(false);
  const [size, setSize] = useState({
    width: "400px",
    height: "300px",
  });
  const inputRef = useRef();

  const styles = {
    solidBorder: {
      borderColor: borderData.color,
      borderStyle: "solid",
      borderWidth: borderData.width,
      width: `${size.width + (borderData.width === "30px" ? 60 : 40)}px`,
    },
    gradientBorder: {
      borderWidth: "30px",
      borderStyle: "solid",
      borderImage: `linear-gradient(${borderData.color}, ${borderData.colorGradient}) 30`,
      borderRadius: "20px",
      width: `${size.width + 60}px`,
    },
  };

  const onImageClicked = () => {
    if (!editing) {
      return;
    }
    inputRef.current.click();
  };

  const onBorderChoose = (borderData) => {
    blocksContext.onImageBorderChanged(
      borderData.type === "noBorder" ? {} : borderData,
      imageNumber
    );
    setShowModal(false);
  };

  const onBorderColorChanged = (e) => {
    let border = { ...borderData };
    border.color = e.target.value;
    blocksContext.onImageBorderChanged(border, imageNumber);
  };
  const onBorderGradientColorChanged = (e) => {
    let border = { ...borderData };
    border.colorGradient = e.target.value;
    blocksContext.onImageBorderChanged(border, imageNumber);
  };

  useEffect(() => {
    setSize(getTypeSize(type));
  }, []);

  const getTypeSize = (type) => {
    if (type === "medium") {
      return { width: 300, height: 300 };
    } else if (type === "small") {
      return { width: 200, height: 200 };
    } else if (type === "big") {
      return { width: 400, height: 400 };
    } else if (type === "big-rect") {
      return { width: 533, height: 400 };
    } else if (type === "medium-rect") {
      return { width: 400, height: 300 };
    }
  };

  return (
    <div
      className={`d-flex justify-content-center position-relative rounded-3`}
      style={{
        ...(borderData.type === "image" && {
          width: `${size.width + 60}px`,
          border: borderData.border,
          borderImage: borderData.borderImage,
        }),
        ...(borderData.type === "solid" && styles.solidBorder),
        ...(borderData.type === "gradient" && styles.gradientBorder),
        ...(!borderData.type && { width: `${size.width}px` }),
      }}
    >
      {editing && (
        <div className="p-1 position-absolute image-instruments-container bg-light rounded border">
          <div className="d-flex justify-content-center flex-column">
            {blocksContext.loadingImage.imageLoading &&
            blocksContext.loadingImage.imageLoadingNumber === imageNumber ? (
              <img src="../../spinner.svg" width="30px" height="30px" />
            ) : (
              <a
                className="btn btn-sm btn-outline-dark instrument-button editor-control-sm p-0 m-0 my-1"
                onClick={() => {
                  inputRef.current.click();
                }}
                title="Выбрать изображение"
              >
                <IoImageOutline size={16} />
              </a>
            )}
            {imageUri && (
              <a
                className="btn btn-sm btn-outline-dark instrument-button editor-control-sm p-0 m-0 my-1"
                onClick={() => {
                  setShowModal(true);
                }}
                title="Рамка"
              >
                <IoScanOutline size={18} />
              </a>
            )}
            {(borderData.type === "solid" ||
              borderData.type === "gradient") && (
              <input
                className="btn btn-sm btn-outline-dark p-1 editor-control-sm instrument-button"
                value={borderData.color}
                type="color"
                onChange={onBorderColorChanged}
                title="Основной цвет"
              />
            )}
            {borderData.type === "gradient" && (
              <input
                className="btn btn-sm btn-outline-dark p-1 editor-control-sm instrument-button"
                value={borderData.colorGradient}
                type="color"
                onChange={onBorderGradientColorChanged}
                title="Дополнительный цвет"
              />
            )}
          </div>
        </div>
      )}
      {imageUri !== "" ? (
        <div
          className={`bg-light ${!borderData.type && "rounded"}`}
          style={{
            width: "100%",
            paddingTop: `${(size.height / size.width) * 100}%`,
            backgroundImage: `url(${imageUri})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      ) : (
        <div
          className="d-flex img-thumbnail bg-light justify-content-center align-items-center"
          style={{
            position: "relative",
            width: "100%",
            paddingTop: `${(size.height / size.width) * 100}%`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <IoImageOutline
            size={80}
            style={{ position: "absolute", bottom: "40%" }}
          />
        </div>
      )}
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        style={{ visibility: "hidden", position: "absolute" }}
        onChange={(e) => {
          if (!editing) {
            return;
          }
          blocksContext.onImagePicked(e, imageNumber);
        }}
      />
      <Modal show={showModal} size={"lg"}>
        <Modal.Header>
          <strong>Выберите рамку</strong>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="row row-cols-2 row-cols-lg-3 g-4">
            {bordersContext.map((border, i) => (
              <div
                className="col d-flex justify-content-center align-items-center"
                key={i}
              >
                <BorderTypeCard
                  borderData={border}
                  onClick={onBorderChoose}
                  imageUri={imageUri}
                />
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary rounded-pill"
            onClick={() => setShowModal(false)}
          >
            Закрыть
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Image;
