import React from "react";

const NotFound = () => {
  return (
    <section style={{ paddingTop: "100px" }} className="block bg-light pb-5">
      <div className="container">
        <div className="d-flex align-items-center flex-column">
          <h1 className="header">404</h1>
          <p className="text-center w-75">Страница не найдена</p>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
