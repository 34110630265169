import React from "react";
import Image from "./../editor/Image";

//one image
const BlockType5 = ({
  blockData = {},
  editing = true,
  onImagePicked = () => {},
}) => {
  return (
    <div className="row container-fluid">
      <div className="col-12 d-flex justify-content-center">
        <Image
          editing={editing}
          imageUri={blockData.images[0]?.uri}
          onImagePicked={onImagePicked}
          type="big-rect"
          imageNumber={0}
          borderData={blockData.images[0]?.border}
        />
      </div>
    </div>
  );
};

export default BlockType5;
