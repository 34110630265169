import React from "react";
import Image from "./../editor/Image";

//three images
const BlockType7 = ({
  blockData = {},
  editing = true,
  onImagePicked = () => {},
}) => {
  return (
    <div className="row">
      <div className="col d-flex justify-content-center align-items-center flex-column py-1">
        <Image
          editing={editing}
          imageUri={blockData.images[0]?.uri}
          onImagePicked={onImagePicked}
          type="small"
          imageNumber={0}
          borderData={blockData.images[0]?.border}
        />
      </div>
      <div className="col d-flex justify-content-center align-items-center flex-column  py-1">
        <Image
          editing={editing}
          imageUri={blockData.images[1]?.uri}
          onImagePicked={onImagePicked}
          type="small"
          imageNumber={1}
          borderData={blockData.images[1]?.border}
        />
      </div>
      <div className="col d-flex justify-content-center align-items-center flex-column py-1">
        <Image
          editing={editing}
          imageUri={blockData.images[2]?.uri}
          onImagePicked={onImagePicked}
          type="small"
          imageNumber={2}
          borderData={blockData.images[2]?.border}
        />
      </div>
    </div>
  );
};

export default BlockType7;
