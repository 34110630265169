import React from "react";

const Bills = () => {
  return (
    <div className="d-flex flex-column p-4">
      <div>
        <h2>Квитанции</h2>
      </div>
      <hr />
      <table class="table">
        <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">Наименование</th>
            <th scope="col">Дата</th>
            <th scope="col">Цена</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>Открытка</td>
            <td>29.10.2021 г.</td>
            <td>99.00₽</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Bills;
