import React, { useEffect, useState } from "react";
import {
  IoEllipsisHorizontal,
  IoPencil,
  IoShareOutline,
} from "react-icons/io5";
import app from "../data/firebase";

const CardCard = ({
  data = { blocks: [], userId: "", id: "", published: false, name: "" },
  link = "/create",
  onDeleted = () => {},
  onSharePressed = () => {},
}) => {
  const [header, setHeader] = useState("Заголовок");
  const [text, setText] = useState("");

  useEffect(() => {
    let header = data.blocks.find((b) => b.header)?.header;
    let text = data.blocks.find((b) => b.text)?.text;
    setHeader(header);
    setText(text);
  }, []);

  const deleteCard = async () => {
    let sure = window.confirm("Вы уверены?");
    if (!sure) {
      return;
    }
    try {
      await app.firestore().collection("cards").doc(data.id).delete();
      onDeleted(data.id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="card" style={{ marginBottom: "20px" }}>
      <div
        className="card-img-top"
        style={{
          backgroundColor: data.blocks[0]?.backgroundColor || "grey",
          height: "20px",
        }}
      ></div>
      <div className="card-body">
        <a href={"/cards/" + data.id} title={data.name || "Новая открытка"}>
          <h5 className="card-title card-name subheader">
            {data.name || "Новая открытка"}
          </h5>
        </a>
        <p className="card-text" style={{ minHeight: "50px" }}>
          {header || "Отредактируйте ее и отправьте адресату!"}
        </p>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="btn-group">
            <a
              href={link}
              className="btn btn-secondary border-0 primary-bg rounded-pill"
            >
              <IoPencil /> Редактировать
            </a>
          </div>
          <div className="dropdown">
            <a
              className="btn btn-light dropdown-toggle rounded-pill"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <IoEllipsisHorizontal />
            </a>
            <ul
              className="dropdown-menu"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li>
                <button
                  className="btn dropdown-item"
                  onClick={() => {
                    onSharePressed(data.id, data.name);
                  }}
                >
                  Поделиться
                </button>
              </li>
              <li>
                <button
                  className="btn dropdown-item"
                  style={{ color: "red" }}
                  onClick={deleteCard}
                >
                  Удалить
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardCard;
