import React from "react";
import Image from "./../editor/Image";

//two images
const BlockType6 = ({
  blockData = {},
  editing = true,
  onImagePicked = () => {},
}) => {
  return (
    <div className="row container-fluid justify-content-center">
      <div className="col col-md-6 py-2 py-lg-0 d-flex align-items-center justify-content-center">
        <Image
          editing={editing}
          imageUri={blockData.images[0]?.uri}
          onImagePicked={onImagePicked}
          type="medium-rect"
          borderData={blockData.images[0]?.border}
          imageNumber={0}
        />
      </div>
      <div className="col col-md-6 py-2 py-lg-0 d-flex align-items-center justify-content-center">
        <Image
          editing={editing}
          imageUri={blockData.images[1]?.uri}
          onImagePicked={onImagePicked}
          type="medium-rect"
          borderData={blockData.images[1]?.border}
          imageNumber={1}
        />
      </div>
    </div>
  );
};

export default BlockType6;
