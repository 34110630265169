import React, { useState, useRef } from "react";
import Image from "./../editor/Image";

const BlockTypeYouTube = ({
  blockData = {},
  editing = true,
  onVideoPicked = () => {},
}) => {
  const [videoUrl, setVideoUrl] = useState("");
  const frame = useRef();

  return (
    <div className="container row justify-content-center">
      {!blockData.videoUrl ? (
        <div className="d-flex flex-row justify-content-center align-items-center">
          <input
            onChange={(e) => {
              let url;
              try {
                url = new URL(e.target.value);
              } catch {
                return;
              }
              let vId = "";
              if (e.target.value.includes("youtu.be")) {
                vId = url.pathname.split("/")[1];
                console.log(vId);
              } else {
                vId = url.searchParams.get("v");
              }

              setVideoUrl(`https://www.youtube.com/embed/${vId}`);
            }}
            placeholder="Вставьте ссылку на видео"
          />
          <button
            className="btn btn-sm btn-primary mx-3"
            onClick={() => {
              onVideoPicked(videoUrl);
            }}
          >
            Вставить
          </button>
        </div>
      ) : (
        <div className="ratio ratio-16x9 col-12" style={{ maxWidth: "800px" }}>
          <iframe
            src={blockData.videoUrl}
            className="embed-responsive-item "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default BlockTypeYouTube;
