import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import "./style.scss";
import CardCreator from "./pages/CardCreator";
import NotFound from "./pages/NotFound";
import MainLayout from "./layouts/MainLayout";
import CreateLayout from "./layouts/CreateLayout";
import Login from "./pages/Login";
import ProfileLayout from "./layouts/ProfileLayout";
import Signup from "./pages/Signup";
import AuthProvider, { AuthContext } from "./contexts/AuthContext";
import CardsPage from "./pages/CardsPage";
import Bills from "./pages/Bills";
import CardPublishedPage from "./pages/CardPublishedPage";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <MainLayout>
              <Home />
            </MainLayout>
          </Route>
          <Route path="/login">
            <MainLayout>
              <Login />
            </MainLayout>
          </Route>
          <Route path="/signup">
            <MainLayout>
              <Signup />
            </MainLayout>
          </Route>
          <Route path="/create">
            <CreateLayout>
              <CardCreator />
            </CreateLayout>
          </Route>
          <Route exact path="/cards">
            <ProfileLayout>
              <CardsPage />
            </ProfileLayout>
          </Route>
          <Route exact path="/cards/:id">
            <CardPublishedPage />
          </Route>
          <Route path="/bills">
            <ProfileLayout>
              <Bills />
            </ProfileLayout>
          </Route>
          <Route path="*">
            <MainLayout>
              <NotFound />
            </MainLayout>
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
