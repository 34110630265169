import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import app from "../data/firebase";

const NavBar = () => {
  const { currentUser, isAuthLoading, username } = useContext(AuthContext);
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark primary-bg">
      <div className="container-fluid">
        <a
          className="navbar-brand pt-0"
          style={{ fontFamily: "Caveat", fontSize: "22px" }}
          href="/"
        >
          <img src="./bonfetta_logo.svg" height="30px" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="/#">
                Главная
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/#about">
                О проекте
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/#pricing">
                Цена
              </a>
            </li> */}

            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Еще
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item" href="/#">
                    Справка
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/#">
                    Поддержка
                  </a>
                </li>
              </ul>
            </li> */}
            {currentUser ? (
              <li className="nav-item">
                <a className="nav-link" href="/cards">
                  Мои открытки
                </a>
              </li>
            ) : (
              <li className="nav-item">
                <a className="nav-link" href="/#login">
                  Вход
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
