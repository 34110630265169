import React, { useState, useRef, useEffect } from "react";

const EditableText = ({
  placeholder,
  editing = true,
  text,
  fontSize = 16,
  onTextChanged = (e) => {},
  fontWeight = "normal",
  type,
  color = "#000",
  fontFamily = "Pacifico",
}) => {
  const contentRef = useRef();

  useEffect(() => {
    contentRef.current.innerText = text;
  }, []);

  return (
    <div
      className="container-fluid text-center editor-input"
      style={{ border: "none" }}
    >
      <div
        ref={contentRef}
        style={{
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: color,
          fontFamily: fontFamily,
        }}
        contentEditable={editing}
        onInput={(e) => {
          onTextChanged(type, e.target.innerText);
        }}
        onChange={(e) => {
          onTextChanged(type, e.target.innerText);
        }}
      ></div>
    </div>
  );
};

export default EditableText;
