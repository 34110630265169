import React, { useEffect, useRef, useState, useContext } from "react";
import BlocksContext from "../../contexts/BlocksContext";
import Image from "./../editor/Image";

const BlockTypeAudio = ({
  blockData = {},
  editing = true,
  audioLoading = false,
  onAudioPicked = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const audio = useRef();
  useEffect(() => {
    if (blockData.audioUrl) {
      audio.current.volume = 0.4;
      // audio.current.play();
    }
  }, [blockData.audioUrl]);

  const blocksContext = useContext(BlocksContext);

  return (
    <div className="row w-50">
      {blocksContext.audioLoading && (
        <div className="d-flex flex-column align-items-center">
          <img src="../spinner.svg" width="50px" height="50px" />
          <span>
            Не закрывайте окно и не сохраняйте открытку. Идет загрузка!
          </span>
        </div>
      )}
      {!blockData.audioUrl ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <input
            type="file"
            accept="audio/*"
            onChange={blocksContext.onAudioPicked}
          />
          <div>
            <span>(Рекомендуем расположить этот блок внизу)</span>
          </div>
        </div>
      ) : (
        <audio
          id="audio"
          ref={audio}
          src={blockData.audioUrl}
          controls
          loop
          onLoadStart={() => setLoading(true)}
          onLoad={() => setLoading(false)}
        ></audio>
      )}
    </div>
  );
};

export default BlockTypeAudio;
