import React, { useContext, useState } from "react";
import { Redirect, useHistory } from "react-router";
import { AuthContext } from "../contexts/AuthContext";
import app from "../data/firebase";
import { toast, ToastContainer } from "react-toastify";

const Signup = () => {
  const history = useHistory();
  const { currentUser } = useContext(AuthContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    let { name, email, pass, passConf } = e.target.elements;
    if (pass.value !== passConf.value) {
      toast.error("Пароли не совпадают", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      await app.auth().createUserWithEmailAndPassword(email.value, pass.value);
      let uid = app.auth().currentUser.uid;
      console.log(uid);
      app.firestore().collection("users").doc(uid).set({
        name: name.value,
        email: email.value,
      });
      history.push("/cards");
    } catch (error) {
      let message = "Неизвестная ошибка! Попробуйте позже!";
      if (error.code == "auth/email-already-in-use") {
        message =
          "Пользователь с таким адресом электронной почты уже зарегистрирован";
      }
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  if (currentUser) {
    return <Redirect to="/cards" />;
  }

  return (
    <div className="py-5">
      <div className="col-5 container-fluid d-flex justify-content-center align-items-center mt-4">
        <div className="d-flex container-fluid flex-column justify-content-center align-items-center">
          <h1>Регистрация</h1>
          <form
            className="d-flex container-fluid align-items-start flex-column mt-4"
            onSubmit={onSubmit}
          >
            <label for="login">Имя</label>
            <div class="input-group mb-3">
              <input
                id="name"
                name="name"
                type="text"
                class="form-control"
                placeholder="Иван"
                aria-label="Логин"
                aria-describedby="basic-addon1"
                required
              />
            </div>
            <label for="login">Email</label>
            <div class="input-group mb-3">
              <input
                id="email"
                name="email"
                type="email"
                class="form-control"
                placeholder="ivan@example.com"
                aria-label="Логин"
                aria-describedby="basic-addon1"
                required
              />
            </div>
            <label for="pass">Пароль</label>
            <div class="input-group mb-3">
              <input
                id="pass"
                name="pass"
                type="password"
                class="form-control"
                placeholder="Пароль"
                aria-label="Логин"
                aria-describedby="basic-addon1"
                required
              />
            </div>
            <label for="pass">Подтверди пароль</label>
            <div class="input-group mb-3">
              <input
                id="passConf"
                name="passConf"
                type="password"
                class="form-control"
                placeholder="Подтвердите пароль"
                aria-label="Логин"
                aria-describedby="basic-addon1"
                required
              />
            </div>
            <div className="container-fluid p-0">
              <input
                type="submit"
                class="btn btn-lg btn-secondary w-100 rounded-pill primary-bg border-0"
                value="Зарегистрироваться"
              />
            </div>
          </form>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Signup;
